import { RouteComponentProps, withRouter } from "react-router"

import {Header} from "../../components/Header/Header"
import {Footer} from "../../components/Footer/Footer"
import AboutPage from "./AboutPage";
import TermsOfServicePage from "./TermsOfServicePage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import CookiePolicyPage from "./CookiePolicyPage";
import globalEventHandler from "../../lib/globalEventHandler";

import './StaticPage.scss'

const onEvent = (op: string, params: any): void => {
    console.log('StaticPage onEvent', op, params)
    switch (op) {
        default:
            globalEventHandler(op, params)
    }
}

interface StaticPageRouterProps {
    slug: string
}

const StaticPage: React.FC<RouteComponentProps<StaticPageRouterProps>> = ({match}) => {
    const {slug} = match.params

    return (
        <>
            <div className={"wrapper"}>
                <Header onEvent={onEvent}/>
                <div className="container static-page">
                    {slug === 'about' && <AboutPage />}
                    {slug === 'terms-of-service' && <TermsOfServicePage />}
                    {slug === 'privacy-policy' && <PrivacyPolicyPage />}
                    {slug === 'cookie-policy' && <CookiePolicyPage />}
                </div>
                <div className={"push"}/>
            </div>
            <Footer onEvent={onEvent}/>
        </>
    )
};

export default withRouter(StaticPage);