
const eq = function(a, b) {
  // eslint-disable-next-line eqeqeq
  return a == b;
};

const uniq = a => [...new Set(a)];

const countTrueElements = arr => {
  const filtered = arr.filter(elem => elem) || [];
  return filtered.length;
};

const countUniqueNumbers = arr => {
  const sanitized = arr.filter(elem => elem !== null && elem > 0) || [];
  return uniq(sanitized).length;
};

const isObjectInArray = function (obj, arr) {
  const keys = Object.keys(obj)
  const found = arr.find(elem => {
    for (let i = 0; i < keys.length; i++) {
      if (!(keys[i] in elem) || !eq(elem[keys[i]], obj[keys[i]])) {
        return false
      }
    }
    return true
  }) || {}
  return (keys[0] in found)
};

const makeSafeForCSS = function (name) {
  return encodeURIComponent(
    name.toLowerCase()
  ).replace(/%[0-9A-F]{2}/gi, '-')
}

const pickValues = function (src, allowedKeys) {
  let output = {};
  allowedKeys.forEach(key => {
    if (key in src) {
      output[key] = src[key];
    }
  });
  return output;
};

const randomElement = arr => {
  const idx = Math.floor(arr.length * Math.random());
  return arr[idx];
};

const range = n => {
  const arr = [];
  for (let i = 0; i < n; i++) {
    arr.push(i);
  }
  return arr;
};

const safeJSON = (unsafe, fallbackValue) => {
  try {
    return !unsafe ? fallbackValue : JSON.parse(unsafe);
  } catch (e) {
    return fallbackValue;
  }
}

const toTitleCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export {
  countTrueElements,
  countUniqueNumbers,
  eq,
  isObjectInArray,
  makeSafeForCSS,
  pickValues,
  randomElement,
  range,
  safeJSON,
  toTitleCase,
  uniq
};