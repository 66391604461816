import React from "react";
import {RouteComponentProps, withRouter} from "react-router"

import {Header} from "../../components/Header/Header"
import {Footer} from "../../components/Footer/Footer"
import {LinkButton} from "../../components/LinkButton/LinkButton";
import globalEventHandler from "../../lib/globalEventHandler"
import useTranslation from "../../hooks/useTranslation"
import countries from "../../data/countries";
import villages from "../../data/villages";

import './IndexPage.scss'

const onEvent = (op: string, params: any): void => {
    console.log('IndexPage onEvent', op, params)
    switch (op) {
        default:
            globalEventHandler(op, params)
    }
}

const i18n = {
    "en": {
        "mission": "Our mission is to promote villages of the world that do not get enough limelight.",
        "catalog": "We collect villages in a catalog that you can browse by country. At this very moment we list $villages villages in $countries countries:",
        "catalog-views": "You can also find your villages of interest from the World Map, browse through categories or just stumble upon a random one."
    },
    "ru": {
        "mission": "RU Our mission is to promote villages of the world that do not get enough limelight.",
        "catalog": "RU We collect villages in a catalog that you can browse by country. At this very moment we list $villages villages in $countries countries:",
        "catalog-views": "RU You can also find your villages of interest from the World Map, browse through categories or just stumble upon a random one."
    }
}

const countryStats = countries.map(country => {
    const {iso3, name, villages} = country
    return {iso3, name, cnt: villages.length}
}).filter(country => country.cnt > 0)

const cntVisibleVillages = Object.values(villages).filter(village => !village.hidden).length

const IndexPage: React.FC<RouteComponentProps> = () => {
    const t = useTranslation(i18n)
    return (
        <>
            <div className="wrapper">
                <div style={{backgroundImage: "url(/amvill-cover-1.jpg)", height: "400px", backgroundSize: "cover"}} />
                <Header onEvent={onEvent}/>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-md-12 col-sm-12"} style={{margin: "40px 0"}}>
                            {t("mission")} &nbsp;
                            {t("catalog", {villages: cntVisibleVillages, countries: Object.keys(countryStats).length})} &nbsp;
                            <p>
                                {countryStats.map((country, index) => {
                                    return (
                                        <LinkButton key={index} href={"/country/" + country.iso3} text={country.name}/>
                                    )
                                })}
                            </p>
                            {t("catalog-views")}
                        </div>
                    </div>
                </div>
                <div className={"push"} />
            </div>
            <Footer onEvent={onEvent}/>
        </>
    )
}

export default withRouter(IndexPage)