import {useHistory} from "react-router-dom";
import {Button} from "react-bootstrap";

import './LinkButton.scss';

export function LinkButton(props: { text?: string, href: string, className?: string, children?: any }) {
    const history = useHistory();
    const goToHref = (href: string) : void => {
        if (href.toLowerCase().startsWith("http")) {
            window.location.href = href
        } else {
            history.push(href)
        }
    }

    return (
        <Button variant={'link'} className={'link ' + props.className}
           onClick={() => goToHref(props.href)}>
            {props.text}{props.children}
        </Button>
    );
}

