import React from "react"
import {RouteComponentProps, withRouter} from "react-router"
import {Helmet} from "react-helmet"

import {Header} from "../../components/Header/Header"
import {Footer} from "../../components/Footer/Footer"
import {Mapbox} from "../../components/Mapbox/Mapbox"
import globalEventHandler from "../../lib/globalEventHandler"
import useTranslation from "../../hooks/useTranslation"

import villages from "../../data/villages"

import './WorldMapPage.scss'

const onEvent = (op: string, params: any): void => {
    console.log('WorldMapPage onEvent', op, params)
    switch (op) {
        default:
            globalEventHandler(op, params)
    }
}

const i18n = {
    "en": {
        "world-map": "World Map",
        "meta-title": "World map - AmazingVillages",
        "meta-description": "All $cnt villages from our catalog shown on the world map."
    },
    "ru": {
        "world-map": "Kарта мира",
        "meta-title": "Удивительные деревни на карте мира",
        "meta-description": "Все $cnt деревень из нашего каталога на карте мира."
    }
}

const WorldMapPage: React.FC<RouteComponentProps> = () => {
    const t = useTranslation(i18n)
    const villageList = Object.values(villages)

    return (
        <>
            <div className="wrapper">
                <Header onEvent={onEvent}/>
                <div className="container">
                    <Helmet>
                        <title>{t("meta-title")}</title>
                        <meta name="description" content={t("meta-description", {cnt: villageList.length})} />
                    </Helmet>

                    <h1>{t("world-map")}</h1>
                    <Mapbox center={{lat: 42.35, long: -70.9, zoom: 0}} villages={villageList}/>
                </div>
                <div className={"push"} />
            </div>
            <Footer onEvent={onEvent}/>
        </>
    )
}

export default withRouter(WorldMapPage)