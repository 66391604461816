import React from "react"

import LinkSet from "../LinkSet/LinkSet"
import useTranslation from "../../hooks/useTranslation"

import './Footer.scss'
import allLanguages from "../../data/languages";

const i18n = {
    "en": {
        "brand": "AmazingVillages",
        "catalog": "Catalog",
        "about-us": "About us",
        "terms-conditions": "Terms & Conditions",
        "privacy-policy": "Privacy Policy",
        "cookie-policy": "Cookie Policy",
        "world-map": "World Map",
        "wishlist": "Wishlist",
        "accommodation": "Accommodation",
        "activities": "Activities",
        "on-twitter": "AmazingVillages on Twitter",
        "copyright": "Copyright © Barcodexana 2015-2021.",
        "accepted-policies": "Use of this website constitutes acceptance of our Terms of Service and Privacy Policy."
    },
    "ru": {
        "brand": "AmazingVillages",
        "catalog": "Каталог",
        "about-us": "О проекте",
        "terms-conditions": "Правила пользования",
        "privacy-policy": "Политика конфиденциальности",
        "cookie-policy": "Использование Cookie",
        "world-map": "Карта мира",
        "wishlist": "Закладки",
        "accommodation": "Размещение",
        "activities": "Чем заняться",
        "on-twitter": "AmazingVillages в Твиттере",
        "copyright": "Copyright © Barcodexana 2015-2021.",
        "accepted-policies": "Use of this website constitutes acceptance of our Terms of Service and Privacy Policy."
    }
}

type FooterProps = {
    onEvent: (op: string, params: any) => void
}

export function Footer(props: FooterProps) {
    const {onEvent} = props
    const t = useTranslation(i18n)
    const commonLinks = [
        {href: "/static/about", text: t('about-us')},
//        {href: "/static/terms-of-service", text: t('terms-conditions')},
        {href: "/static/privacy-policy", text: t('privacy-policy')},
        {href: "/static/cookie-policy", text: t('cookie-policy')}
    ]
    const catalogLinks = [
        {href: "/world", text: t("world-map")},
        {href: "/wishlist", text: t("wishlist")},
//        {href: "/accommodation", text: t("accommodation")},
//        {href: "/activities", text: t("activities")}
    ]
    const socialMediaLinks = [
        {href: "https://twitter.com/amazingvillages", text: t("on-twitter")}
    ]

    return (
        <footer>
            <div className="container footer__top">
                <div className="row row-fluid">
                    <div className="col-md-4 col-sm-12 about-us">
                        <h3>{t("brand")}</h3>
                        <LinkSet links={commonLinks}/>
                    </div>

                    <div className="col-md-4 col-sm-12 clients">
                        <h3>{t("catalog")}</h3>
                        <LinkSet links={catalogLinks}/>
                    </div>

                    <div className="col-md-4 pull-right col-sm-12">
                        <h3>&nbsp;</h3>
                        <div className="row footer__settings">
                            <FooterLanguageSelection onEvent={onEvent}/>
                        </div>
                        <div className="row footer-sp">
                            <LinkSet links={socialMediaLinks}/>
                        </div>
                    </div>
                </div>
                <div className="row footer__bottom disclaimer">
                    <div className="col-md-12">
                        <p style={{fontSize: "smaller", paddingBottom: "20px"}}>
                            {t("copyright")}
                            {t("accepted-policies")}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

function FooterLanguageSelection(props: { onEvent: (prop: string, params: any) => void }) {
    const {onEvent} = props
    const setLanguage = (languageCode: string) => onEvent('setLanguage', {newLanguage: languageCode})

    return (
        <div className={"footer__language"}>
            {allLanguages.map((language, index) => {
                return (
                    <span key={index} className={"language__" + language.code}
                          onClick={() => setLanguage(language.code)}/>
                )
            })}
        </div>
    )
}
