import React from "react"
import {Helmet} from "react-helmet"

import useTranslation from "../../hooks/useTranslation"

const i18n = {
    "en": {
        "privacy-policy": "Our privacy policy",
        "meta-description": "We do not ask for any of your private data and we do not use any trackers - although we integrate with booking.com who does.",
        "do-not-gather": "We don't gather any information about you, our site only provides information and does not ask anything in return.",
        "do-not-profile": "There are no trackers on our site - we don't have a slightest idea about who you are, how long do you read our pages, which sites you might visit on the same computer etc.",
        "booking": "However, we have an IFRAME-integration with booking.com. This means that on some of our pages, you will see their booking engine. Since it's just another website embedded into our page, some information of yours can be tracked by booking.com themselves. We work on introducing an alternative way of integrating with booking.com that will eliminate also this kind of tracking.",
        "do-not-mail": "Just because we never ask you for your email address, you will never get spammed from us, either!"
    },
    "ru": {
        "privacy-policy": "Наша политика использования личных данных",
        "meta-description": "Мы не спрашиваем у вас никаких личных данных и не устанавливаем никаких трекеров - однако, мы интегрируем booking.com, которые делают это.",
        "do-not-gather": "Мы не собираем никакой информации о Вас. Наш сайт только предоставляет Вам информацию и не требует ничего взамен.",
        "do-not-profile": "В коде нашего сайта нет ничего, что следило бы за Вами. У нас нет не малейшего представления о том, кто Вы, как вы пользуетесь нашим сайтом и какие ещё страницы вы открываете на своём устройстве.",
        "booking": "Однако, в данный момент мы предоставляем IFRAME-интеграцию с сервисом booking.com. Это значит, что на некоторых страницах нашего сайта есть встроенное окно, в котором открыта страница booking.com, на которой можно зарезервировать жильё в местности, соотвествующей странице нашего сайта. Это соотвествует тому, как если бы вы открыли booking.com в другом окне вашего браузера, а значит, booking.com может собирать какие-то данные о Вас согласно их соглашению об использвании частных данных. Мы работаем над тем, чтобы интеграция с booking.com происходила более щадящим для вас образом.",
        "do-not-mail": "Поскольку мы не спрашиваем Ваш адрес электронной почты, то естественно, мы не сможем послать Вам сообщений."
    }
}

const PrivacyPolicyPage = () => {
    const t = useTranslation(i18n)

    return (
        <div className={"privacy-policy-page"}>
            <Helmet>
                <title>{t("privacy-policy")}</title>
                <meta name="description" content={t("meta-description")} />
            </Helmet>

            <h1>{t("privacy-policy")}</h1>
            <p>{t("do-not-gather")} {t("do-not-profile")}</p>
            <p>{t("booking")}</p>
            <p>{t("do-not-mail")}</p>
        </div>
    )
}

export default PrivacyPolicyPage