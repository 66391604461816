import React from "react"
import {Helmet} from "react-helmet"

import useTranslation from "../../hooks/useTranslation"

const i18n = {
    "en": {
        "about": "About the project",
        "goal": "Out goal is to make rural tourism more popular.",
        "your-support": "You can support us",
        "book-hotels": "We will appreciate if you book accommodation for your next trip, starting browsing of booking.com on our site. In this case, we receive small commission from your booking - without you paying any extra.",
        "provide-info": "And if you want to recommend us another amazing village or provide your photos for the ones we already have in our catalog - you are very welcome!"
    },
    "ru": {
        "about": "О проекте",
        "goal": "Наша цель - популяризация деревенского туризма.",
        "your-support": "Как вы можете поддержать нас",
        "book-hotels": "Если для следующей вашей поездки вы забронируете жильё на booking.com, попав на их сайт по одной из наших ссылок, мы получим небольшую комиссию - при этом вы заплатите ровно столько же, как если бы вы пришли на сайт booking.com по любой другой ссылке.",
        "provide-info": "Мы будем рады также вашим советам по поводу того, какие ещё деревни достойны появления в нашем каталоге, а также вашим фотографиям тех деревень, которые уже выставлены на обозрение."
    }
}

const AboutPage = () => {
    const t = useTranslation(i18n)

    return (
        <div className={"about-page"}>
            <Helmet>
                <title>{t("about")}</title>
                <meta name="description" content={t("goal")} />
            </Helmet>

            <h1>{t("about")}</h1>
            <p>{t("goal")}</p>
            <h3>{t("your-support")}</h3>
            <p>{t("book-hotels")}</p>
            <p>{t("provide-info")}</p>
        </div>
    )
}

export default AboutPage