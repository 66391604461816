import React from "react"
import {Nav, Navbar, NavDropdown} from "react-bootstrap"

import useTranslation from "../../hooks/useTranslation"
import allLanguages from "../../data/languages";

import './Header.scss'

const i18n = {
    "en": {
        "brand": "Amazing Villages",
        "world": "World map",
        "wishlist": "Wishlist",
        "accommodation": "Accommodation",
        "activities": "Activities"
    },
    "ru": {
        "brand": "Amazing Villages",
        "world": "Карта мира",
        "wishlist": "Закладки",
        "accommodation": "Размещение",
        "activities": "Чем заняться"
    }
}

type HeaderProps = {
    onEvent: (op: string, params: any) => void
}

export function Header(props: HeaderProps) {
    const {onEvent} = props
    const t = useTranslation(i18n)

    return (
        <Navbar expand="lg" sticky="top" variant={"dark"}>
            <div className={"container"}>
            <Navbar.Brand href="/">{t('brand')}</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href={"/world"}>{t('world')}</Nav.Link>
                    <Nav.Link href={"/wishlist"}>{t('wishlist')}</Nav.Link>
                    {/*<Nav.Link href={"/accommodation"}>{t('accommodation')}</Nav.Link>*/}
                    {/*<Nav.Link href={"/activities"}>{t('activities')}</Nav.Link>*/}
                    <LanguageSelection onEvent={onEvent}/>
                </Nav>
            </Navbar.Collapse>
            </div>
        </Navbar>
    )
}

function LanguageSelection(props: { onEvent: (op: string, params?: any) => void }) {
    const activeLanguageCode = localStorage.getItem("language") || "en"
    const activeLanguage = allLanguages.find(language => language.code === activeLanguageCode) || allLanguages[0]
    console.log("LanguageSelection", allLanguages, activeLanguage)

    return (
        <NavDropdown title={activeLanguage.title} id="nav-dropdown-language">
            {allLanguages.filter(language => language.code !== activeLanguageCode).map((language, index) => {
                return (
                    <NavDropdown.Item key={index} onClick={() => props.onEvent('setLanguage', {newLanguage: language.code})}>{language.title}</NavDropdown.Item>
                )
            })}
        </NavDropdown>
    )
}