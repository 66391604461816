import useLanguage from "./useLanguage";
import {merge} from "../lib/strings";

const useTranslation = (translations: any) => {
    const language = useLanguage();
    // TODO make sure that some sort of interpolation is supported
    return (key: string, params?: any): string => {
        const languageTranslations = translations[language] || {};
        return (key in languageTranslations)
            ? merge(languageTranslations[key], params)
            : key;
    }
}

export default useTranslation;