import countries from "../data/countries";
import allVillages from "../data/villages";

const getCountryInfo = (iso3: string): any => {
    const countryInfo = countries.find(country => country.iso3 === iso3)
    if (!countryInfo || !countryInfo.villages || countryInfo.villages.length === 0) {
        return {}
    }

    const villages = countryInfo.villages
        .map(id => allVillages[id])
        .filter(village => village && "id" in village)

    // @ts-ignore
    return {
        ...countryInfo,
        villages
    }
}

export default getCountryInfo