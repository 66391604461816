import React, {useEffect, useState} from "react"
import {RouteComponentProps, withRouter} from "react-router"
import {Helmet} from "react-helmet"

import {Header} from "../../components/Header/Header"
import {Footer} from "../../components/Footer/Footer"
import globalEventHandler from "../../lib/globalEventHandler"
import getCountryInfo from "../../service/getCountryInfo"
import NotFoundPage from "../NotFoundPage/NotFoundPage"
import {Mapbox} from "../../components/Mapbox/Mapbox"
import VillageRow from "../../components/VillageRow/VillageRow"
import {addToWishlist, getWishlist, removeFromWishlist} from "../../lib/wishlist"
import useTranslation from "../../hooks/useTranslation"

import images from "../../data/image.json"

import './CountryDetailsPage.scss'

const i18n = {
    "en": {
        "meta-title": "$countryName on Amazing Villages",
        "meta-description": "Country description for $countryName. $cnt villages are in the catalog!",
        "village-counter": "We found $cnt villages for you"
    },
    "ru": {
        "meta-title": "$countryName: удивительные деревени",
        "meta-description": "Описание страны $countryName. В нашем каталоге $cnt деревень",
        "village-counter": "Мы нашли для вас $cnt деревень"
    }
}

interface CountryDetailsRouterProps {
    slug: string
}

const CountryDetailsPage: React.FC<RouteComponentProps<CountryDetailsRouterProps>> = ({match}) => {
    const t = useTranslation(i18n)
    const [loading, setLoading] = useState(true)
    const [countryInfo, setCountryInfo] = useState<any>({})

    useEffect(() => {
        const countryInfoAtMount = getCountryInfo(match.params.slug)
        if (countryInfoAtMount.villages) {
            const wishlistAtMount = getWishlist()
            countryInfoAtMount.villages.forEach((villageInfo: any) => {
                villageInfo.isInWishList = wishlistAtMount.indexOf(villageInfo.id) > -1
                villageInfo.coverImageInfo = villageInfo.coverImage
                    ? images.find(image => image.id === villageInfo.coverImage)
                    : {}
            })
        }
        setCountryInfo(countryInfoAtMount)
        setLoading(false)
        // eslint-disable-next-line
    }, [])

    const setVillageInWishlistFlag = (id: string, value: boolean): void => {
        const newCountryInfo = {...countryInfo}
        newCountryInfo.villages = countryInfo.villages.map((village: any) => {
            if (village.id === id) {
                village.isInWishList = value
            }
            return village
        })
        setCountryInfo(newCountryInfo)
    }

    const onEvent = (op: string, params: any): void => {
        switch (op) {
            case "addToWishlist":
                addToWishlist(params.villageToAdd)
                setVillageInWishlistFlag(params.villageToAdd, true)
                break
            case "removeFromWishlist":
                removeFromWishlist(params.villageToRemove)
                setVillageInWishlistFlag(params.villageToAdd, false)
                break
            default:
                globalEventHandler(op, params)
        }
    }

    const {lat, lng, zoom} = countryInfo
    const center = {lat, long: lng, zoom}

    if (loading) {
        return <div>Loading...</div>
    }

    if (!countryInfo.id) {
        return <NotFoundPage/>
    }

    return (
        <>
            <div className="wrapper">
                <Helmet>
                    <title>{t("meta-title", {countryName: countryInfo.name})}</title>
                    <meta name="description" content={t("meta-description", {countryName: countryInfo.name})} />
                </Helmet>

                <Header onEvent={onEvent}/>
                <div className={"container country-details-page"}>
                    <div className={"intro"}>
                        <h1>{countryInfo.name}</h1>
                    </div>

                    <div className={"row country-header"}>
                        <div className="col-md-4 lead">
                            {countryInfo.description}
                        </div>
                        <div className="col-md-8">
                            <Mapbox center={center} villages={Object.values(countryInfo.villages)}/>
                        </div>
                    </div>

                    {countryInfo.villages.length > 0 && (
                        <h3>{t('village-counter', {cnt: countryInfo.villages.length})}</h3>
                    )}

                    {countryInfo.villages.map((village: any) => {
                        return (
                            <VillageRow className={"row village-row"} key={village.id} village={village}
                                        onEvent={onEvent} buttons={["details", "bookHotel", "toggleWishlist"]}/>
                        )
                    })}
                </div>
                <div className={"push"}/>
            </div>
            <Footer onEvent={onEvent}/>
        </>
    )
}

export default withRouter(CountryDetailsPage)