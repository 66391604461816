import React from "react";
import {RouteComponentProps, withRouter} from "react-router"
import {Helmet} from "react-helmet"

import {Header} from "../../components/Header/Header"
import {Footer} from "../../components/Footer/Footer"
import globalEventHandler from "../../lib/globalEventHandler"
import useTranslation from "../../hooks/useTranslation";

import './NotFoundPage.scss'

const i18n = {
    "en": {
        "not-found": "Oopsie, is it 404?",
        "page-not-found": "The page you requested does not exist"
    },
    "ru": {
        "not-found": "Упс? 404?",
        "page-not-found": "Запрашиваемая Вами страница не найдена"
    }
}

const NotFoundPage: React.FC<RouteComponentProps> = () => {
    const t = useTranslation(i18n)
    const onEvent = (op: string, params: any): void => {
        switch (op) {
            default:
                globalEventHandler(op, params)
        }
    }

    return (
        <>
            <div className="wrapper">
                <Header onEvent={onEvent}/>
                <div className="container">
                    <Helmet>
                        <title>{t("not-found")}</title>
                        <meta name="description" content={t("page-not-found")} />
                    </Helmet>

                    <h1>{t("not-found")}</h1>
                    <p>{t("page-not-found")}</p>
                </div>
                <div className={"push"} />
            </div>
            <Footer onEvent={onEvent}/>
        </>
    )
}

export default withRouter(NotFoundPage)