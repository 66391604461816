import React, {useState} from "react";

import {GalleryImageItem} from "../../components/Gallery/types";

function VillageGalleryItem(props: { item: GalleryImageItem }) {
    const {item} = props
    const {width, height, itemDetails} = item
    const [hover, setHover] = useState(false)
    return (
        <div style={{width, height}}
             onMouseEnter={() => setHover(true)}
             onMouseLeave={() => setHover(false)}>
            <img src={itemDetails.thumb} alt={"© " + itemDetails.author_name}
                 style={{cursor: "pointer", width, height}}/>
            {hover && (
                <div style={{
                    position: "relative",
                    marginTop: "-50px",
                    height: "50px",
                    backgroundColor: "#fff",
                    opacity: 1
                }}>
                    <p style={{backgroundColor: "white", fontSize: "smaller", width: "150px"}}>
                        © <a href={item.itemDetails.page} target="_blank"
                             rel="noreferrer">{item.itemDetails.author_name}</a>
                    </p>
                </div>
            )}
        </div>
    )
}

export default VillageGalleryItem