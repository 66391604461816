import React, {useEffect, useRef} from "react";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'
import {Village} from "../../data/types";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

type LatLongZoom = {
    lat: number,
    long: number,
    zoom: number
}

type MapboxProps = {
    center: LatLongZoom,
    villages: Village[],
    onEvent?: (op: string, params: any) => void
}

export function Mapbox(props: MapboxProps) {
    const {center, villages} = props
    const mapContainer = useRef(null)
    const mapRef = useRef(null)

    const prepareFeature = (village: Village) => {
        return {
            'type': 'Feature',
            'properties': {
                'id': village.id,
                'title': village.name,
                'description': village.teaser,
                'icon': 'garden-15'
            },
            'geometry': {
                'type': 'Point',
                'coordinates': [village.lng, village.lat]
            }
        }
    }

    useEffect(() => {
        if (mapRef.current) return // initialize map only once

        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [center.long, center.lat],
            zoom: center.zoom
        })

        map.on('load', function () {
            map.addSource('places', {
                // This GeoJSON contains features that include an "icon"
                // property. The value of the "icon" property corresponds
                // to an image in the Mapbox Streets style's sprite.
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': villages.filter(village => !village['hidden']).map(prepareFeature)
                }
            })
            map.addLayer({
                'id': 'places',
                'type': 'symbol',
                'source': 'places',
                'layout': {
                    'icon-image': '{icon}',
                    'icon-allow-overlap': true
                }
            })

            type MapClickEventParam = {
                features: {
                    geometry: any;
                    properties: { id: string, title: string, description: any };
                }[];
                lngLat: { lng: number };
            }
            // When a click event occurs on a feature in the places layer, open a popup at the
            // location of the feature, with description HTML from its properties.
            map.on('click', 'places', function (e: MapClickEventParam) {
                let coordinates = e.features[0].geometry.coordinates.slice();
                const {id, title, description} = e.features[0].properties
                const html = '<h4><a href="/village/' + id + '-' + encodeURIComponent(title) + '">' + title + '</a></h4>' + description

                // Ensure that if the map is zoomed out such that multiple
                // copies of the feature are visible, the popup appears
                // over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                new mapboxgl.Popup()
                    .setLngLat(coordinates)
                    .setHTML(html)
                    .addTo(map);
            });

            // Change the cursor to a pointer when the mouse is over the places layer.
            map.on('mouseenter', 'places', function () {
                map.getCanvas().style.cursor = 'pointer';
            });

            // Change it back to a pointer when it leaves.
            map.on('mouseleave', 'places', function () {
                map.getCanvas().style.cursor = '';
            });
        })
        mapRef.current = map
    })

    return (
        <div ref={mapContainer} className="map-container"/>
    );
}