import React from "react"
import {Helmet} from "react-helmet"

import useTranslation from "../../hooks/useTranslation"

const i18n = {
    "en": {
        "cookie-policy": "Our cookie policy",
        "meta-description": "We don't ask you to accept some sort of cookie policy - just because we don't use cookies at all!",
        "no-popup": "You might have already noticed that unlike many other sites, we don't ask you to accept some sort of cookie policy.",
        "no-cookies": "It is so not because we ignore the legislation on this subject - we just don't use cookies at all :)"
    },
    "ru": {
        "cookie-policy": "Наша политика использования cookies",
        "meta-description": "Мы не просим вашего согласия с какой-то политикой cookies - просто потому что мы не используем их!",
        "no-popup": "Возможно, Вы уже заметили, что в отличие от многих других сайтов, amazingvillages.com не требует от Вас согласия с использованием cookies.",
        "no-cookies": "Это объясняется не тем, что мы не выполняем законы о сохранении частной информации - просто мы не используем cookies :)"
    }
}

const CookiePolicyPage = () => {
    const t = useTranslation(i18n)

    return (
        <div className={"cookie-policy-page"}>
            <Helmet>
                <title>{t("cookie-policy")}</title>
                <meta name="description" content={t("meta-description")} />
            </Helmet>

            <h1>{t("cookie-policy")}</h1>
            <p>{t("no-popup")}</p>
            <p>{t("no-cookies")}</p>
        </div>
    )
}

export default CookiePolicyPage