import React from "react";
import {GalleryImageItem} from "./types";

type GalleryImageProps = {
    item: GalleryImageItem,
    index: number,
    margin: number,
    renderFn?: (item: GalleryImageItem) => JSX.Element,
    onClick?: (item: GalleryImageItem) => void
};

export function GalleryImage(props: GalleryImageProps) {
    const onClick = props.onClick ? props.onClick : () => {};
    const renderFn = props.renderFn;
    const { width, height } = props.item;

    return (
        <div className="ReactGridGallery_tile"
             style={{
                 margin: props.margin,
                 WebkitUserSelect: "none",
                 position: "relative",
                 float: "left",
                 background: "#eee",
                 padding: "0",
                 width: width,
                 height: height
             }}>

            <div className="ReactGridGallery_tile-viewport"
                 key={"tile-viewport-" + props.index}
                 onClick={(e) => onClick(props.item)}>
                {renderFn ? renderFn(props.item): null}
            </div>
        </div>
    );
}
