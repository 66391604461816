import React from "react"
import {Button} from "react-bootstrap"

import useTranslation from "../../hooks/useTranslation"
import {LinkButton} from "../LinkButton/LinkButton"
import getBookingUrl from "../../lib/getBookingUrl"

const i18n = {
    "en": {
        "btn.details": "View details »",
        "btn.book": "Book a hotel »",
        "btn.addToWishlist": "Add to wishlist »",
        "btn.removeFromWishlist": "Remove from wishlist »"
    },
    "ru": {
        "btn.details": "Подробнее »",
        "btn.book": "Забронировать номер »",
        "btn.addToWishlist": "Добавить в закладки »",
        "btn.removeFromWishlist": "Убрать из закладок »"
    }
}

function VillageRow(props: { className: string, village: any, buttons: string[], onEvent: (op: string, params: any) => void }) {
    const {village, buttons, onEvent} = props
    const t = useTranslation(i18n)

    const addToWishlist = buttons.indexOf("addToWishlist") > -1
        || (buttons.indexOf("toggleWishlist") > -1 && !village.isInWishList)
    const removeFromWishlist = buttons.indexOf("removeFromWishlist") > -1
        || (buttons.indexOf("toggleWishlist") > -1 && village.isInWishList)

    return (
        <div className={props.className}>
            <div className="col-md-3 col-sm-4">
                <a href={"/village/" + village.id}>
                    <VillageThumbnail imageInfo={village.coverImageInfo} />
                </a>
            </div>
            <div className="col-md-9 col-sm-8">
                <h3 className="featurette-heading">{village.name}</h3>
                <p>{village.teaser}</p>
                <p className="hidden-print">
                    {buttons.indexOf("details") > -1 && (
                        <LinkButton className="btn btn-default" href={"/village/" + village.id}
                                    text={t('btn.details')}/>
                    )}
                    {buttons.indexOf("bookHotel") > -1 && (
                        <a className="btn btn-default"
                           href={getBookingUrl(village.lat, village.lng, village.zoom)}
                           target="_blank" role="button" rel="noreferrer noopener">{t('btn.book')}</a>
                    )}
                    {addToWishlist && (
                        <Button className="btn btn-default"
                                onClick={() => onEvent('addToWishlist', {villageToAdd: village.id})}>{t("btn.addToWishlist")}</Button>
                    )}
                    {removeFromWishlist && (
                        <Button className="btn btn-default"
                                onClick={() => onEvent("removeFromWishlist", {villageToRemove: village.id})}>{t("btn.removeFromWishlist")}</Button>

                    )}
                </p>
            </div>
        </div>
    )
}

function VillageThumbnail(props: {imageInfo: any}) {
    const {imageInfo} = props
    return imageInfo.thumb ? (
        <img
            className="featurette-image img-responsive coverphoto-thumbnail"
            width="150" height="150"
            src={imageInfo.thumb}
            alt={""}/>
    ) : null
}

export default VillageRow