import React from "react"
import {BrowserRouter, Route} from "react-router-dom"
import 'mapbox-gl/dist/mapbox-gl.css'
import {Helmet} from "react-helmet"

// import AccommodationPage from "./pages/Accommodation/AccommodationPage"
// import ActivitiesPage from "./pages/Activities/ActivitiesPage"
import CountryDetailsPage from "./pages/CountryDetails/CountryDetailsPage"
import IndexPage from "./pages/Index/IndexPage"
import StaticPage from "./pages/Static/StaticPage"
import VillageDetailsPage from "./pages/VillageDetails/VillageDetailsPage"
import WorldMapPage from "./pages/WorldMap/WorldMapPage"
import WishlistPage from "./pages/Wishlist/WishlistPage"

import useTranslation from "./hooks/useTranslation"

import './App.scss'

const i18n = {
    "en": {
        "title": "Amazing Villages",
        "meta-description": "Inspirational catalog of villages worth visiting"
    },
    "ru": {
        "title": "Amazing Villages - Удивительные деревни",
        "meta-description": "Каталог деревень со всего мира, которые стоило бы посетить"
    }
}

function App() {
    const t = useTranslation(i18n)
    return (
        <>
            <Helmet>
                <title>{t("title")}</title>
                <meta name="description" content={t("meta-description")} />
            </Helmet>
            <BrowserRouter>
                <Route path="/" exact component={IndexPage}/>
                <Route path="/world" exact component={WorldMapPage}/>
                <Route path="/wishlist" exact component={WishlistPage}/>
                <Route path="/country/:slug" exact component={CountryDetailsPage}/>
                <Route path="/village/:slug" exact component={VillageDetailsPage}/>
                {/*<Route path="/accommodation" exact component={AccommodationPage} />*/}
                {/*<Route path="/activities" exact component={ActivitiesPage}/>*/}
                <Route path="/static/:slug" exact component={StaticPage}/>
            </BrowserRouter>
        </>
    )
}

export default App
