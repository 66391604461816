import villages from "../data/villages";
import countries from "../data/countries";
import images from "../data/image.json";

const getVillageInfo = (id: string): any => {
    const villageInfo = villages[id]
    if (!villageInfo || villageInfo.hidden) {
        return {}
    }

    const countryInfo = countries.find(country => country.id === villageInfo.country)
    if (!countryInfo) {
        return {}
    }

    const coverImageInfo = villageInfo.coverImage
        ? images.find(image => image.id === villageInfo.coverImage)
        : {}

    const galleryImages = images.filter(image => image.object_type === "village" && image.object_id === id)

    return {
        ...villageInfo,
        countryInfo,
        coverImageInfo,
        galleryImages
    }
}

export default getVillageInfo