import React, {useEffect, useState} from 'react';
import {GalleryImage} from "./GalleryImage";
import {GalleryImageItem} from "./types";

type GalleryProps = {
    id: string,
    margin: number, // margin around each gallery image
    rowHeight: number, // NB! This is minimal value for height. Real value will be higher
    galleryWidth: number, // TODO calculate on resize and move out of props
    images: GalleryImageItem[],
    renderFn?: (item: GalleryImageItem) => JSX.Element,
    onItemClick?: (item: GalleryImageItem) => void
};

export function Gallery(props: GalleryProps) {
    const [thumbnails, setThumbnails] = useState<any[]>([]);

    // force resize (to recalculate thumbnails) when properties change
    useEffect(() => {
        resize();
        // eslint-disable-next-line
    }, [props]);

    // on every resize, recalculate thumbnails; they might change their dimensions
    const resize = () => {
        setThumbnails(recalculateThumbnails(props.galleryWidth, props.images))
    }

    // make additional scaling for row images to fill the entire row space horizontally
    const scaleToFit = (row: GalleryImageItem[], currentRowWidth: number, containerWidth: number): GalleryImageItem[] => {
        const totalMargins = 2 * props.margin * row.length;
        const multiplier = (containerWidth - totalMargins) / (currentRowWidth - totalMargins);
        return row.map(item => {
            item.width = Math.floor(item.width * multiplier);
            item.height = Math.floor(props.rowHeight * multiplier);
            return item;
        });
    }

    // divide flow of images into rows of same width (except the last row)
    const divideImagesToRows = (items: GalleryImageItem[], containerWidth: number) => {
        const imgMargin = 2 * props.margin;
        const rows = [];
        let currentRow: any[] = [];
        let currentRowWidth = 0;

        while (items.length > 0) {
            // @ts-ignore
            const item: GalleryImageItem = items.shift();
            const requiredSpace = (item.width + imgMargin);
            if (requiredSpace < containerWidth - currentRowWidth) { // fits with no problem
                currentRow.push(item);
                currentRowWidth += requiredSpace;
            } else if (requiredSpace > containerWidth * 0.8) { // super-panoramic image, push to its own row
                rows.push(scaleToFit([item], item.width, containerWidth));
            } else { // finish the current row and start a new one
                rows.push(scaleToFit(currentRow, currentRowWidth, containerWidth));
                currentRow = [item];
                currentRowWidth = requiredSpace;
            }
        }

        // all items are fetched, but last row may be unreleased yet; push it, too
        if (currentRow.length > 1) {
            rows.push(currentRow); // no need to scale the row because it's anyway not complete
        }

        return rows;
    }

    const recalculateThumbnails = (containerWidth: number, images: GalleryImageItem[]) => {
        if (!images) return [];
        if (containerWidth === 0) return [];

        // scale all images to fixed `props.rowHeight` and variable `item.width`:
        const items = images.map(item => {
            const ratio = (item.width / item.height)
            item.height = props.rowHeight;
            item.width = Math.floor(props.rowHeight * ratio);
            return item;
        });

        // divide in rows and scale images per row so they take the full `containerWidth`:
        const rowsOfItems = divideImagesToRows(items, containerWidth);

        // flatten the array for floating layout:
        return rowsOfItems.flat();
    }

    return (
        <div id={props.id} className="ReactGridGallery clearfix" style={{width: props.galleryWidth}}>
            {thumbnails.map((item, idx) => {
                return (
                    <GalleryImage
                        key={"Image-" + idx + "-" + item.src}
                        item={item}
                        index={idx}
                        margin={props.margin}
                        renderFn={props.renderFn}
                        onClick={props.onItemClick}
                    />
                );
            })}
        </div>
    );
}