import React, {useState} from "react"
import {RouteComponentProps, withRouter} from "react-router"
import {Helmet} from "react-helmet"

import {Header} from "../../components/Header/Header"
import {Footer} from "../../components/Footer/Footer"
import {Mapbox} from "../../components/Mapbox/Mapbox"
import globalEventHandler from "../../lib/globalEventHandler"
import useTranslation from "../../hooks/useTranslation"
import {safeJSON} from "../../lib/util"
import VillageRow from "../../components/VillageRow/VillageRow"
import {removeFromWishlist} from "../../lib/wishlist"

import images from "../../data/image.json"
import villages from "../../data/villages"

import './WishlistPage.scss'

const getWishlistVillages = (): any[] => {
    const wishlist = safeJSON(localStorage.getItem("wishlist"), []) || []
    const villageInfos: any[] = wishlist
        .map((id: string) => villages[id])
        .filter((village: any) => village && "id" in village)

    villageInfos.forEach((villageInfo: any) => {
        villageInfo.coverImageInfo = villageInfo.coverImage
            ? images.find(image => image.id === villageInfo.coverImage)
            : {}
    })

    return villageInfos
}

const i18n = {
    "en": {
        "wishlist": "Wishlist",
        "meta-title": "Wishlist - AmazingVillages",
        "meta-description": "$cnt villages on your wishlist - shown as list and on the world map",
        "empty": "Your wishlist is currently empty.",
        "click2add": "Click 'add to wishlist' button anywhere on our site to see your bookmarks appearing on this page.",
        "2views": "After you will have added some villages to your wishlist, you'll see them as a list and also as a map.",
        "cookies": "We do not save cookies, so your wishlist disappears after you have closed your browser!",
        "world-map": "World Map",
        "village-counter": "You have $cnt villages in your wishlist"
    },
    "ru": {
        "wishlist": "Закладки",
        "meta-title": "Закладки - Удивительные деревни",
        "meta-description": "$cnt ваших любимых деревень - в виде списка и на карте мира.",
        "empty": "Вы ещё не сделали ни одной закладки",
        "click2add": "Нажмите кнопку  'Добавить в закладки' в любом месте сайта, чтобы на этой странице появились ваши любимые деревни.",
        "2views": "После того, как Вы добавите деревни в список закладок, вы увидите их на этой странице в виде списка и в виде карты.",
        "cookies": "Мы не сохраняем куки на вашем компьютере, поэтому список пропадёт при закрытии окна браузера!",
        "world-map": "Kарта мира",
        "village-counter": "У вас $cnt закладок"
    }
}

const WishlistPage: React.FC<RouteComponentProps> = () => {
    const t = useTranslation(i18n)
    const [wishlistVillages, setWishlistVillages] = useState(getWishlistVillages())

    const onEvent = (op: string, params: any): void => {
        switch (op) {
            case "removeFromWishlist":
                removeFromWishlist(params.villageToRemove)
                setWishlistVillages(getWishlistVillages())
                break
            default:
                globalEventHandler(op, params)
        }
    }

    return (
        <>
            <div className="wrapper">
                <Header onEvent={onEvent}/>
                <div className="container wishlist-page">
                    <Helmet>
                        <title>{t("meta-title")}</title>
                        <meta name="description" content={t("meta-description", {cnt: wishlistVillages.length})} />
                    </Helmet>

                    <h1>{t("wishlist")}</h1>
                    {wishlistVillages.length === 0 ? (
                        <>
                            <p>{t('empty')}</p>
                            <p>{t('click2add')}</p>
                            <p>{t('2views')}</p>
                            <p>{t('cookies')}</p>
                        </>
                    ) : (
                        <>
                            <Mapbox center={{lat: 42.35, long: -70.9, zoom: 0}} villages={wishlistVillages}/>

                            {wishlistVillages.length > 0 && (
                                <h3>{t('village-counter', {cnt: wishlistVillages.length})}</h3>
                            )}

                            {wishlistVillages.map((villageInfo: any) => {
                                return (
                                    <VillageRow className={"row village-row"} key={villageInfo.id}
                                                village={villageInfo} onEvent={onEvent}
                                                buttons={["details", "bookHotel", "removeFromWishlist"]}/>
                                )
                            })}
                        </>
                    )}
                </div>
                <div className={"push"}/>
            </div>
            <Footer onEvent={onEvent}/>
        </>
    )
}

export default withRouter(WishlistPage)