import {safeJSON, uniq} from "./util";

function getWishlist() {
    return safeJSON(localStorage.getItem("wishlist"), []) || []
}

function addToWishlist(idToAdd: string) {
    const wishlistBeforeAdding = getWishlist()
    const wishlistAfterAdding = uniq([...wishlistBeforeAdding, idToAdd])
    localStorage.setItem("wishlist", JSON.stringify(wishlistAfterAdding))
    return wishlistAfterAdding
}

function removeFromWishlist(idToRemove: string) {
    const wishlistBeforeRemoving = getWishlist()
    const wishlistAfterRemoving = [...wishlistBeforeRemoving].filter(id => id !== idToRemove)
    localStorage.setItem("wishlist", JSON.stringify(wishlistAfterRemoving))
    return wishlistAfterRemoving
}

export {
    addToWishlist,
    getWishlist,
    removeFromWishlist
}