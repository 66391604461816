import React from "react";

import {LinkButton} from "../LinkButton/LinkButton";

type LinkDescription = {
    href: string;
    text: string;
}

export default function LinkSet (props: {links: LinkDescription[]}) {
    return (
        <ul className={"linkSet"}>
            {props.links.map((linkInfo: LinkDescription) => {
                return (
                    <li key={linkInfo.href}>
                        <LinkButton  href={linkInfo.href}>{linkInfo.text}</LinkButton>
                    </li>
                )
            })}
        </ul>
    )
}
