import React, {useEffect, useState} from "react"
import {RouteComponentProps, withRouter} from "react-router"
import {Button} from "react-bootstrap"
import {Helmet} from "react-helmet"

import {Header} from "../../components/Header/Header"
import {Footer} from "../../components/Footer/Footer"
import globalEventHandler from "../../lib/globalEventHandler"
import getVillageInfo from "../../service/getVillageInfo"
import {LinkButton} from "../../components/LinkButton/LinkButton"
import getBookingUrl from "../../lib/getBookingUrl"
import {Mapbox} from "../../components/Mapbox/Mapbox"
import {Gallery} from "../../components/Gallery/Gallery"
import {GalleryImageItem} from "../../components/Gallery/types"
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import useTranslation from "../../hooks/useTranslation"
import {merge} from "../../lib/strings"
import VillageGalleryItem from "./VillageGalleryItem"
import {addToWishlist, removeFromWishlist} from "../../lib/wishlist"

import './VillageDetailsPage.scss'

const i18n = {
    "en": {
        "home": "Home",
        "meta-title": "$villageName - one of Amazing Villages",
        "meta-description": "Village description for $villageName, including image gallery and accommodation booking",
        "categories": "Categories",
        "addToWishlist": "Add to wishlist »",
        "removeFromWishlist": "Remove from wishlist »",
        "larger-map": "Click to open larger map in new window",
        "we-handpicked": "We have handpicked some photos for you.",
        "please-visit-photographers": "Please click copyright links to visit photographers' pages.",
        "photo-by": "© Photo by $authorName",
        "gallery-for": "Image gallery for $villageName",
        "book-hotel": "Book hotels in/around $villageName with Booking.com",
        "on-instagram": "What's happening near $villageName on Instagram"
    },
    "ru": {
        "home": "Главная",
        "meta-title": "$villageName - одна из Удивительных деревень",
        "meta-description": "Описание деревни $villageName, включая фотогалерею и бронирование жилья",
        "categories": "Категории",
        "addToWishlist": "Добавить в закладки »",
        "removeFromWishlist": "Удалить из закладок »",
        "larger-map": "Большая карта в новом окне",
        "we-handpicked": "Мы подобрали для вас изображения деревни.",
        "please-visit-photographers": "Вы можете посетить галереи фотографов, кликнув на ссылки",
        "photo-by": "© Фото $authorName",
        "gallery-for": "Галерея изображений для деревни $villageName",
        "book-hotel": "Забронируйте отеле в деревне $villageName с помощью Booking.com",
        "on-instagram": "Что происходит в районе деревни $villageName на Instagram"
    }
}

interface VillageDetailsRouterProps {
    slug: string
}

const VillageDetailsPage: React.FC<RouteComponentProps<VillageDetailsRouterProps>> = ({match}) => {
    const [id] = match.params.slug.split("-")
    const t = useTranslation(i18n)
    const [villageInfo, setVillageInfo] = useState<any>({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setVillageInfo(getVillageInfo(id))
        setLoading(false)
        // eslint-disable-next-line
    }, [])

    if (loading) {
        return <div>Loading...</div>
    }

    if (!villageInfo.id) {
        return <NotFoundPage/>
    }

    const {lat, lng, zoom} = villageInfo
    const bookingUrl = getBookingUrl(lat, lng, zoom)
    const googleMapsUrl = "https://maps.google.com/maps/@" + lat + "," + lng + "," + zoom + "z"
    const mapCenter = {lat, long: lng, zoom}

    const showGallery = villageInfo.galleryImages.length > 1
    const showHotels = true
    const showInstagram = false

    const setVillageInWishlistFlag = (value: boolean): void => {
        const newVillageInfo = {...villageInfo}
        newVillageInfo.isInWishlist = value
        setVillageInfo(newVillageInfo)
    }

    const onEvent = (op: string, params: any): void => {
        switch (op) {
            case "addToWishlist":
                addToWishlist(params.villageToAdd)
                setVillageInWishlistFlag(true)
                break
            case "removeFromWishlist":
                removeFromWishlist(params.villageToRemove)
                setVillageInWishlistFlag(false)
                break
            default:
                globalEventHandler(op, params)
        }
    }

    return (
        <>
            <div className="wrapper">
                <Header onEvent={onEvent}/>
                <div className="container">
                    <Helmet>
                        <title>{t("meta-title", {villageName: villageInfo.name})}</title>
                        <meta name="description" content={t("meta-description", {villageName: villageInfo.name})} />
                    </Helmet>

                    <ol className="breadcrumb">
                        <li><LinkButton href={"/"} text={t('home')}/></li>
                        <li><LinkButton href={"/country/" + villageInfo.countryInfo.iso3}
                                        text={villageInfo.countryInfo.name}/></li>
                        <li className="active btn">{villageInfo.name}</li>
                    </ol>
                    <div className="village">

                        <div className="village-intro">
                            <h1>{villageInfo.name}</h1>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <VillageThumbnail imageInfo={villageInfo.coverImageInfo} t={t}/>
                            </div>
                            <div className="col-md-4">
                                <div className="form-horizontal">
                                    {villageInfo.categories && villageInfo.categories.length && (
                                        <div className="form-group">
                                            <label className="col-sm-3">{t('categories')}</label>
                                            <div className="col-sm-9 clearfix">

                                            </div>
                                        </div>
                                    )}
                                </div>
                                <p>{villageInfo.teaser}</p>
                                <p>
                                    {villageInfo.isInWishlist ? (
                                        <Button onClick={() => onEvent('removeFromWishlist', {village: villageInfo.id})}
                                                className="btn btn-default">{t("removeFromWishlist")}</Button>
                                    ) : (
                                        <Button onClick={() => onEvent('addToWishlist', {village: villageInfo.id})}
                                                className="btn btn-default">{t("addToWishlist")}</Button>
                                    )}
                                </p>
                            </div>
                            <div className="col-md-4">
                                <Mapbox center={mapCenter} villages={[villageInfo]}/>
                                <a href={googleMapsUrl} target="_blank" rel="noreferrer">
                                    <p>{t('larger-map')}</p>
                                </a>
                            </div>
                        </div>

                        {showGallery && (
                            <div className="panel panel-default" style={{margin: "20px 0"}}>
                                <div className="panel-heading">
                                    <h4>{t('gallery-for', {villageName: villageInfo.name})}</h4>
                                </div>
                                <div className="gallery-description" style={{margin: "20px 0"}}>
                                    {t("we-handpicked")}
                                    {t("please-visit-photographers")}
                                </div>
                                <Gallery id={"gallery"} margin={2} rowHeight={150} galleryWidth={800}
                                         images={villageInfo.galleryImages.map((itemDetails: any) => {
                                             return {itemDetails, width: 150, height: 150}
                                         })}
                                         renderFn={(item: GalleryImageItem) => <VillageGalleryItem item={item}/>}/>
                            </div>
                        )}

                        {showHotels && (
                            <div className="panel panel-default" id={"hotels_" + villageInfo.id}>
                                <div className="panel-heading">
                                    <h4>{t('book-hotel', {villageName: villageInfo.name})}</h4>
                                </div>
                                <div className="panel-body" style={{height: "600px"}}>
                                    <iframe title={t('book-hotel', {villageName: villageInfo.name})}
                                            className="bookingDotCom" frameBorder="0" src={bookingUrl}
                                            style={{height: "100%", width: "100%"}}/>
                                </div>
                            </div>
                        )}

                        {showInstagram && (
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4>{t('on-instagram', {villageName: villageInfo.name})}</h4>
                                </div>
                                <div className="panel-body" id="instamedia">
                                </div>
                            </div>
                        )}

                    </div>
                </div>
                <div className="push"/>
            </div>
            <Footer onEvent={onEvent}/>
        </>
    )
}

function VillageThumbnail(props: { imageInfo: any, t: typeof merge }) {
    const {imageInfo, t} = props

    return imageInfo.src && imageInfo.page ? (
        <>
            <img src={imageInfo.src} className="img-responsive coverphoto-full" width={"100%"}
                 alt={t("photo-by", {authorName: imageInfo.author_name})}/>
            <p style={{width: "100%"}}>
                © <a href={imageInfo.page}>{imageInfo.author_name}</a>
            </p>
        </>
    ) : null
}

export default withRouter(VillageDetailsPage)