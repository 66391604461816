
const globalEventHandler = (op: string, params: any): void => {
    console.log("globalEventHandler", op, params)
    switch (op) {
        case "setLanguage":
            const {newLanguage} = params
            const oldLanguage = localStorage.getItem("language")
            newLanguage !== "" && newLanguage !== oldLanguage && localStorage.setItem("language", newLanguage)
            window.location.reload()
            break
    }
}

export default globalEventHandler